<template>
  <div class="contact">
    <el-row id="contactSection">
      <el-col>
        <h2 class="contact-h2">{{ $t("landingPage.contact.sayhi.title") }}</h2>
        <p class="contact-p">{{ $t("landingPage.contact.sayhi.para") }}</p>
      </el-col>
    </el-row>
    <!-- contact -->
    <el-row class="rowClass contactCaptcha" justify="center">
      <!-- contact info others -->
      <el-col :span="24" :md="12" class="colSection">
        <el-divider>
          <h5 class="titleSection">{{ $t("landingPage.contact.title") }}</h5>
        </el-divider>

        <!-- email -->
        <div class="contactItem pt-4">
          <el-tooltip
            class="item"
            :enterable="false"
            :content="$t('landingPage.contact.email')"
            placement="top"
            popper-class="tooltipColor"
          >
            <div class="input-icon icon-email"></div>
          </el-tooltip>
          <div class="itemContent">
            {{ isoCodeIsCn ? "china@ivy-way.com" : "contact@ivy-way.com" }}
          </div>
        </div>

        <!-- line -->
        <div v-if="!isoCodeIsCn" class="contactItem">
          <el-tooltip
            class="item"
            :enterable="false"
            :content="$t('landingPage.contact.line')"
            placement="top"
            popper-class="tooltipColor"
          >
            <a target="_blank" href="https://page.line.me/ivyway">
              <div class="input-icon icon-line"></div>
            </a>
          </el-tooltip>
          <a
            style="text-decoration: none"
            target="_blank"
            href="https://page.line.me/ivyway"
          >
            <div style="color: #90a4ae" class="itemContent">
              @ivyway
              {{ $t("landingPage.contact.lineTip") }}
            </div>
          </a>
          <div>
            <el-popover placement="top" trigger="hover">
              <div class="input-icon icon-qr d-flex" slot="reference"></div>
              <a target="_blank" href="https://page.line.me/ivyway">
                <img width="140" height="140" :src="lineQRcode" />
              </a>
            </el-popover>
          </div>
        </div>

        <!-- facebook -->
        <div v-if="!isoCodeIsCn" class="contactItem">
          <el-tooltip
            class="item"
            :enterable="false"
            :content="$t('landingPage.contact.facebook')"
            placement="top"
            popper-class="tooltipColor"
          >
            <a target="_blank" href="https://facebook.com/ivywayacademy">
              <div class="input-icon icon-facebook"></div>
            </a>
          </el-tooltip>
          <a
            style="text-decoration: none"
            target="_blank"
            href="https://facebook.com/ivywayacademy"
          >
            <div style="color: #90a4ae" class="itemContent">ivywayacademy</div>
          </a>
          <div>
            <el-popover placement="top" trigger="hover">
              <div class="input-icon icon-qr d-flex" slot="reference"></div>
              <a target="_blank" href="https://facebook.com/ivywayacademy">
                <img width="140" height="140" :src="facebookQRcode" />
              </a>
            </el-popover>
          </div>
        </div>

        <!-- IG -->
        <div v-if="!isoCodeIsCn" class="contactItem">
          <el-tooltip
            class="item"
            :enterable="false"
            content="Instagram"
            placement="top"
            popper-class="tooltipColor"
          >
            <a target="_blank" href="https://www.instagram.com/ivywayacademy/">
              <span style="margin-right: 8px; font-size: 24px">
                <i class="fab fa-instagram" />
              </span>
            </a>
          </el-tooltip>
          <a
            style="text-decoration: none"
            target="_blank"
            href="https://www.instagram.com/ivywayacademy/"
          >
            <div style="color: #90a4ae" class="itemContent">ivywayacademy</div>
          </a>
          <div>
            <el-popover placement="top" trigger="hover">
              <div class="input-icon icon-qr d-flex" slot="reference"></div>
              <a
                target="_blank"
                href="https://www.instagram.com/ivywayacademy/"
              >
                <img width="140" height="140" :src="igQRcode" />
              </a>
            </el-popover>
          </div>
        </div>

        <!-- weibo -->
        <div v-if="isoCodeIsCn" class="contactItem">
          <el-tooltip
            class="item"
            :enterable="false"
            :content="$t('landingPage.contact.weibo')"
            placement="top"
            popper-class="tooltipColor"
          >
            <div class="input-icon icon-weibo"></div>
          </el-tooltip>
          <div class="itemContent">ivywayedu</div>
        </div>

        <!-- wechat -->
        <div class="contactItem d-flex">
          <el-tooltip
            class="item"
            :enterable="false"
            :content="$t('landingPage.contact.wechat')"
            placement="top"
            popper-class="tooltipColor"
          >
            <div class="input-icon icon-wechat"></div>
          </el-tooltip>
          <div class="itemContent d-lg-flex d-block">
            <div class="d-flex">
              <div>
                {{ isoCodeIsCn ? "ivywaychina" : "ivywaysat" }}
              </div>
              <div>
                <el-popover placement="top" trigger="hover">
                  <div class="input-icon icon-qr" slot="reference"></div>
                  <div class="d-flex">
                    <div v-if="isoCodeIsCn" class="d-block">
                      <div style="text-align: center">
                        {{ $t("landingPage.contact.shanghai") }}
                      </div>
                      <div>
                        <img width="140" height="140" :src="wechatQRcode1" />
                      </div>
                    </div>
                    <div v-if="!isoCodeIsCn" class="d-block">
                      <div style="text-align: center">
                        {{ $t("landingPage.contact.taiwan") }}
                      </div>
                      <div>
                        <img width="140" height="140" :src="wechatQRcode2" />
                      </div>
                    </div>
                  </div>
                </el-popover>
              </div>
            </div>
            <div v-if="isoCodeIsCn" class="d-flex">
              <div>
                | ivywayedu
                <span>{{ $t("landingPage.contact.official") }}</span>
              </div>
              <div>
                <el-popover placement="top" trigger="hover">
                  <div class="input-icon icon-qr d-flex" slot="reference"></div>
                  <div>
                    <img width="140" height="140" :src="wechatQRcode3" />
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
        </div>

        <!-- QQ -->
        <div v-if="isoCodeIsCn" class="contactItem">
          <el-tooltip
            class="item"
            :enterable="false"
            :content="$t('landingPage.contact.qq')"
            placement="top"
            popper-class="tooltipColor"
          >
            <div class="input-icon icon-qq"></div>
          </el-tooltip>
          <div class="itemContent">2933435017</div>
        </div>

        <!-- phone taiwan -->
        <div class="contactItem">
          <el-tooltip
            class="item"
            :enterable="false"
            :content="$t('landingPage.contact.phone')"
            placement="top"
            popper-class="tooltipColor"
          >
            <div class="input-icon icon-mobile"></div>
          </el-tooltip>
          <div class="itemContent tw-phone">
            <div class="d-flex">
              <div v-if="isoCodeIsCn">
                173-2124-7147
              </div>
              <div v-else v-html="telTW" />
            </div>
          </div>
        </div>
      </el-col>

      <!-- message form -->
      <el-col :span="24" :md="12" class="contact colSection" id="messageForm">
        <el-divider>
          <h5 class="titleSection">
            {{ $t("landingPage.contact.message.title") }}
          </h5>
        </el-divider>

        <el-form
          ref="form"
          :model="messageForm"
          :rules="formRules"
          :validate-on-rule-change="false"
        >
          <el-row :gutter="20">
            <el-col :md="12">
              <el-form-item prop="name">
                <label class="m-0">{{
                  $t("landingPage.contact.message.name")
                }}</label>
                <el-input
                  :placeholder="$t('landingPage.contact.message.name')"
                  v-model="messageForm.name"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item prop="email">
                <label class="m-0">{{
                  $t("landingPage.contact.message.email")
                }}</label>
                <el-input
                  :placeholder="$t('landingPage.contact.message.email')"
                  v-model="messageForm.email"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item prop="phone" style="" :error="errors.phone">
            <label class="m-0">
              Phone
            </label>
            <vue-tel-input
              style="background-color:white;color: black;"
              v-model="messageForm.phone"
              @input="onInput"
            ></vue-tel-input>
          </el-form-item>
          <el-form-item prop="message">
            <label class="m-0">{{
              $t("landingPage.contact.message.pleaseEnter")
            }}</label>
            <div>
              <el-input
                type="textarea"
                :rows="4"
                :placeholder="$t('landingPage.contact.message.pleaseEnter')"
                v-model="messageForm.message"
              ></el-input>
            </div>
          </el-form-item>
          <!-- captcha -->
          <el-form-item prop="captcha" :error="errors.captcha">
            <el-row>
              <el-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <el-image
                  style="cursor:pointer"
                  @click="refreshCaptcha"
                  :src="captchaImgNew"
                  fit="fill"
                ></el-image>
              </el-col>
              <el-col :xs="{ span: 24 }" :sm="{ span: 15, offset: 1 }">
                <el-input
                  v-model="messageForm.captcha"
                  :placeholder="$t('landingPage.contact.message.captcha')"
                >
                </el-input>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" style="float: right">
              {{ $t("landingPage.contact.message.send") }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import formMixin from "@/mixins/form";

export default {
  data() {
    return {
      phone: null,
      messageForm: {
        name: "",
        email: "",
        message: "",
        phoneCode: "",
        phone: "",
        captcha: ""
      },
      wechatQRcode1: require("@/assets/qrcode/ivywaychina_QR.webp"),
      wechatQRcode2: require("@/assets/qrcode/TW_WeChat_QR.webp"),
      wechatQRcode3: require("@/assets/qrcode/ivywayedu_QR.webp"),
      facebookQRcode: require("@/assets/qrcode/ivywayfacebook_QR.webp"),
      igQRcode: require("@/assets/qrcode/ivywayIG_QR.jpg"),
      lineQRcode: require("@/assets/qrcode/line_QR.jpg"),
      telTW: "(02)2370-5397 &#160;|&#160; 0978-038-334",
      routeName: this.$route.name,
      errors: {
        captcha: "",
        phone: ""
      },
      countryCode: "",
      captchaImgNew: "",
      captchaKeyNew: ""
    };
  },
  mixins: [formMixin],
  watch: {
    captchaImg(newValue) {
      this.captchaImgNew = newValue;
    },
    captchaKey(newValue) {
      this.captchaKeyNew = newValue;
    }
  },
  computed: {
    ...mapGetters({
      captchaImg: "captcha/img",
      captchaKey: "captcha/key",
      geoLocation: "ipGeo/getGeoAfterCall"
    }),
    isoCodeIsCn() {
      return this.geoLocation.iso_code === "CN";
    },
    formRules() {
      return {
        name: [
          {
            required: true,
            message: this.$t("landingPage.contact.message.name_require"),
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: this.$t("register.please_input_email"),
            trigger: "blur"
          },
          {
            type: "email",
            message: this.$t("register.please_valid_email"),
            trigger: "blur"
          }
        ],
        message: [
          {
            required: true,
            message: this.$t("landingPage.contact.message.message_require"),
            trigger: "blur"
          }
        ],
        captcha: [
          {
            required: true,
            message: this.$t("landingPage.contact.message.captcha_require"),
            trigger: "blur"
          }
        ],
        phone: [
          {
            required: false,
            message: "phone",
            trigger: "blur"
          }
        ]
      };
    }
  },
  mounted() {
    this.$store.dispatch("captcha/getCaptcha");
    this.$store.dispatch("ipGeo/getGeoLocation");
  },
  methods: {
    onInput(phone, phoneObject) {
      console.log(phoneObject);
      this.messageForm.phoneCode = phoneObject.country.dialCode;
    },
    refreshCaptcha() {
      this.$store.dispatch("captcha/getCaptcha");
    },
    refreshForm() {
      this.messageForm = {
        name: "",
        email: "",
        message: "",
        phone: "",
        phoneCode: "",
        captcha: ""
      };
    },
    async onSubmit() {
      this.errors.captcha = "";
      this.errors.phone = "";
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }
      const { name, email, message, captcha, phone, phoneCode } = this.messageForm;
      const contactReq = { name, email, message, captcha  };
      contactReq["captchaKey"] = this.captchaKeyNew;
      contactReq["phone"] = "+" + phoneCode + phone;

      try {
        await this.$store.dispatch("contact/contactUs", contactReq);
        this.$message({
          message: this.$t("landingPage.contact.message.sendSuccess"),
          type: "success"
        });
        this.refreshCaptcha();
        this.refreshForm();
      } catch (error) {
        let errors = error.data.errors;
        for (let index in errors) {
          this.errors[index] = errors[index][0];
        }

        return error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-button {
  outline: none;
}
/* row */
.rowClass {
  display: flex;
}

@media screen and (max-width: 992px) {
  .rowClass {
    display: block;
  }
}

.colSection {
  background-color: #212121;
  color: white;
  padding: 48px;
}

::v-deep .el-divider__text {
  background-color: #212121;
  color: #bacee6;
}

.titleSection {
  color: white;
  width: 185px;
  text-align: center;
}

@media screen and (max-width: 450px) {
  .titleSection {
    font-size: 18px;
    color: white;
    width: 160px;
    text-align: center;
  }
  ::v-deep .el-divider__text {
    background-color: #212121;
    color: #bacee6;
    padding-left: 5px;
    padding-right: 5px;
  }
  .colSection {
    background-color: #212121;
    color: white;
    padding: 12px;
  }
}

/* contact */
#contactSection {
  text-align: center;
  background-color: rgba(33, 33, 33, 1);
  color: #bacee6;
  height: auto;
  padding-top: 90px;
  padding-bottom: 20px;
}

.contact-h2 {
  font-size: 44px;
  color: white;
}

.contact-p {
  font-size: 20px;
  color: #bacee6;
  padding-right: 140px;
  padding-left: 140px;
}

@media screen and (max-width: 600px) {
  #contactSection {
    padding-top: 40px;
  }
  .contact-p {
    font-size: 18px;
    color: #bacee6;
    padding-right: 12px;
    padding-left: 12px;
  }
}

label {
  display: block;
  font-size: 0.9em;
  font-weight: 600;
  margin: 0 0 1em 0;
}

.contactItem {
  display: flex;
  padding-bottom: 10px;
  color: #90a4ae;
  align-items: center;
}

.itemContent {
  font-size: 0.9em;
  font-weight: 600;
}

.tw-phone {
  display: flex;
}

@media screen and (max-width: 390px) {
  .tw-phone {
    display: block;
  }
}

.input-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 5px;
  background-size: cover;
  background-color: #42a16a;
}

.icon-email {
  mask: url("../assets/svg/email.svg") no-repeat center;
}

.icon-weibo {
  mask: url("../assets/svg/sina-weibo.svg") no-repeat center;
}

.icon-wechat {
  mask: url("../assets/svg/wechat.svg") no-repeat center;
}

.icon-qq {
  mask: url("../assets/svg/qqchat.svg") no-repeat center;
}

.icon-facebook {
  mask: url("../assets/svg/facebook.svg") no-repeat center;
}

.icon-line {
  mask: url("../assets/svg/line-brands.svg") no-repeat center;
}

.icon-mobile {
  mask: url("../assets/svg/cellphone-iphone.svg") no-repeat center;
}

.icon-qr {
  cursor: pointer;
  mask: url("../assets/svg/qrcode.svg") no-repeat center;
}
</style>
