<template>
  <div>
    <!-- banner -->
    <section id="banner">
      <div class="inner">
        <h1>{{ $t("landingPage.banner.title1") }}</h1>
        <h1>{{ $t("landingPage.banner.title2") }}</h1>
        <p>{{ $t("landingPage.banner.para") }}</p>
        <el-button class="bannerBtn" @click="goToContactSection">
          <span>{{ $t("landingPage.banner.btn") }}</span>
        </el-button>
      </div>
    </section>

    <!-- intro & video -->
    <section>
      <el-row class="rowClass" justify="center">
        <el-col :span="24" :md="12" class="p-5 bgLeft">
          <div>
            <h1 class="pb-3" style="font-size:36px">Ivy-Way Education</h1>
            <h5 style="line-height: 30px;">
              {{ $t("landingPage.intro.para") }}
            </h5>
          </div>
        </el-col>
        <el-col :span="24" :md="12" class="videoClass bgRight">
          <div class="p-3">
            <iframe
              width="100%"
              :height="videoHeight"
              :src="getVideo(videoUrl)"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </el-col>
      </el-row>
    </section>

    <!-- instructor's section -->
    <section id="instructorSection" class="instructorClass">
      <div class="inner">
        <header>
          <h1 class="instructor-h1">
            {{ $t("landingPage.instructor.title") }}
          </h1>
          <p class="instructor-p">{{ $t("landingPage.instructor.para") }}</p>
        </header>
        <el-row>
          <el-col
            :span="24"
            :md="8"
            v-for="(instructor, index) in instructors"
            :key="index"
          >
            <div>
              <el-avatar
                shape="circle"
                :size="250"
                :src="instructor.src"
              ></el-avatar>
            </div>
            <h3 class="instructor-uni">{{ instructor.uni }}</h3>
            <p class="instructor-name">{{ instructor.name }}</p>
          </el-col>
        </el-row>
        <div class="mt-5">
          <el-button class="instructorBtn" @click="goToInstructors">
            <span>{{ $t("landingPage.instructor.btn") }}</span>
          </el-button>
        </div>
      </div>
    </section>

    <!-- course's section -->
   
      <section id="courseSection" class="courseClass">
        <div class="inner">
          <header>
            <h1 class="course-h1">{{ $t("landingPage.courses.title") }}</h1>
            <p class="course-p">{{ $t("landingPage.courses.para") }}</p>
          </header>
          <el-row class="course-el-row">
            <el-col
              :span="24"
              :md="8"
              v-for="(course, index) in courses"
              :key="index"
              class="pr-2 pl-2 course-col"
            >
            <a :href="$t(course.path)" style="text-decoration: none;">
              <el-card class="course-card">
                <h3 class="course-title">{{ $t(course.title) }}</h3>
                <p class="course-desc">{{ $t(course.desc) }}</p>
              </el-card>
            </a>
            </el-col>
          </el-row>
          <div class="mt-5">
            <el-button class="courseBtn" @click="goToClasses">
              <span>{{ $t("landingPage.courses.btn") }}</span>
            </el-button>
          </div>
        </div>
      </section>
   

    <!-- stories -->
    <section class="storiesSection">
      <div class="inner">
        <el-carousel :interval="6000" height="400px">
          <el-carousel-item v-for="(story, index) in stories" :key="index">
            <p class="stories-p">
              <span class="quote-icon stories-quote-open"></span>
              {{ $t(story.desc) }}
              <span class="quote-icon stories-quote-close"></span>
            </p>
            <h5 class="stories-h5">— {{ $t(story.author) }}</h5>
          </el-carousel-item>
        </el-carousel>
        <div class="mt-4">
          <el-button class="storiesBtn" @click="goToStories">
            <span>{{ $t("landingPage.stories.btn") }}</span>
          </el-button>
        </div>
      </div>
    </section>

    <!-- enroll -->
    <section id="enrollSection">
      <el-row class="inner">
        <el-col>
          <h2>{{ $t("landingPage.enroll.title") }}</h2>
          <el-button class="enrollBtn" @click="goToEnrollment">
            <span>{{ $t("landingPage.enroll.btn") }}</span>
          </el-button>
        </el-col>
      </el-row>
    </section>

    <!-- contact section -->
    <section id="contact">
      <Contact />
    </section>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

import onlineCourseMixin from "@/mixins/onlineCourse.js";
import Contact from "@/components/Contact";

export default {
  metaInfo() {
    return {
      title: this.$t("landingPage.title")
    };
  },
  data() {
    return {
      videoUrl: "https://youtu.be/ekJ2BImDQu0",
      videoHeight: "300px",
      windowWidth: window.innerWidth,
      instructors: [
        {
          src: require("@/assets/instructor/alex.jpg"),
          uni: "Harvard",
          name: "Alex C."
        },
        {
          src: "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/user/2021-05-03_609014f64bb49_IMG_20210503_232213.jpg",
          uni: "UPenn",
          name: "RJ"
        },
        {
          src: "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/2022-04-18_625d6723105f9_photo_2022-04-04_17-19-25.jpg",
          uni: "Columbia",
          name: "Tina"
        }
      ],
      courses: [
        {
          title: "landingPage.courses.course1.title",
          desc: "landingPage.courses.course1.para",
          path: "landingPage.courses.course1.path"
        },
        {
          title: "landingPage.courses.course2.title",
          desc: "landingPage.courses.course2.para",
          path: "landingPage.courses.course2.path"
        },
        {
          title: "landingPage.courses.course3.title",
          desc: "landingPage.courses.course3.para",
          path: "landingPage.courses.course3.path"
        }
      ],
      stories: [
        {
          desc: "landingPage.stories.story1.desc",
          author: "landingPage.stories.story1.author"
        },
        {
          desc: "landingPage.stories.story2.desc",
          author: "landingPage.stories.story2.author"
        },
        {
          desc: "landingPage.stories.story3.desc",
          author: "landingPage.stories.story3.author"
        },
        {
          desc: "landingPage.stories.story4.desc",
          author: "landingPage.stories.story4.author"
        }
      ],
      currCountry: "",
      languageList: [
        {
          value: "ZH-CN",
          title: "简体中文"
        },
        {
          value: "ZH-TW",
          title: "繁體中文"
        },
        {
          value: "EN-US",
          title: "English"
        }
      ]
    };
  },

  components: { Contact },

  mixins: [onlineCourseMixin],

  props: ["id"],
  computed: {
    isSmall() {
      return this.windowWidth < 400;
    },
    isMid() {
      return this.windowWidth > 750 && this.windowWidth < 992;
    }
  },
  watch: {
    // captchaInitCount(newValue, oldValue) {
    //   if (newValue === 2) {
    //     this.$store.dispatch("captcha/getCaptcha");
    //   }
    // }
  },
  created() {
    const body = document.body;
    body.style.overflow = "overlay";
  },

  mounted() {
    // Listen to the size of current window (for video size)
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      if (this.isSmall) {
        // const h = Math.round(300 * (this.windowWidth / 992));
        this.videoHeight = "200px";
      } else if (this.isMid) {
        this.videoHeight = "450px";
      } else {
        this.videoHeight = "300px";
      }
    });

    // mounted 時的標題動畫特效
    const objBody = document.body;
    objBody.classList.add("isLoading");
    setTimeout(() => {
      objBody.classList.remove("isLoading");
      if (this.$route.name === "Contact") {
        document.querySelector("#contact").scrollIntoView(true);
      }
    }, 300);
  },

  methods: {
    goToContactSection() {
      setTimeout(() => {
        const target = document.getElementById("messageForm");
        target.scrollIntoView({
          block: "start",
          behavior: "instant"
        });
      }, 200);
    },
    goToInstructors() {
      this.$router.push({ name: "Instructors" });
    },
    goToClasses() {
      this.$router.push({ name: "ClassesAndCounseling" });
    },
    goToStories() {
      this.$router.push({ name: "Stories" });
    },

    goToEnrollment() {
      this.$router.push({
        name: "EnrollmentNew"
      });
    }
  }
};
</script>

<style scoped>
::v-deep .el-row {
  margin: 0px;
}

.el-button {
  outline: none;
}

body.isLoading #banner > .inner {
  opacity: 0;
  -moz-transform: translateY(1em);
  -webkit-transform: translateY(1em);
  -ms-transform: translateY(1em);
  transform: translateY(1em);
}

#banner {
  display: -ms-flexbox;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  padding: 8em 0 6em 0;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  min-height: 75vh;
  height: 100vh;
  position: relative;
  background: #000;
  background-image: url(../assets/mainbg.webp);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  color: #fff;
  filter: contrast(90%);
}
#banner:before {
  -moz-transition: opacity 3s ease;
  -webkit-transition: opacity 3s ease;
  -ms-transition: opacity 3s ease;
  transition: opacity 3s ease;
  -moz-transition-delay: 0.25s;
  -webkit-transition-delay: 0.25s;
  -ms-transition-delay: 0.25s;
  transition-delay: 0.25s;
  content: "";
  display: block;
  background-color: #000;
  height: 100%;
  left: 0;
  opacity: 0.65;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

#banner .inner {
  -moz-transform: none;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  -moz-transition: opacity 1s ease, transform 1s ease;
  -webkit-transition: opacity 1s ease, transform 1s ease;
  -ms-transition: opacity 1s ease, transform 1s ease;
  transition: opacity 1s ease, transform 1s ease;
  position: relative;
  opacity: 1;
  z-index: 3;
  padding: 0 2em;
}

#banner h1 {
  font-size: 4em;
  line-height: 1em;
  margin: 0 0 0.5em 0;
  padding: 0;
  color: #fff;
  line-height: 100%;
}

#banner p {
  font-size: 1.5em;
  margin-bottom: 1.75em;
}

#banner a {
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 1280px) {
  #banner h1 {
    font-size: 3.5em;
  }
}

@media screen and (max-width: 736px) {
  #banner {
    background-attachment: scroll;
  }
  #banner h1 {
    font-size: 2.25em;
  }

  #banner p {
    font-size: 1.25em;
  }
}

.bannerBtn {
  font-size: 1.2em;
  padding: 1em 1em;
  color: #eeeeee;
  background: none;
  /* box-shadow: 0 0 0 1px rgba(144, 144, 144, 0.25); */
  transition: 0.5s;
}

.bannerBtn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.bannerBtn span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -10px;
  transition: 0.5s;
  color: white;
}

.bannerBtn:hover,
.bannerBtn:active,
.bannerBtn:focus {
  color: white;
  background: rgba(144, 144, 144, 0.075);
  box-shadow: 0 0 0 2px rgba(144, 144, 144, 0.25);
}

.bannerBtn:hover span {
  padding-right: 20px;
}

.bannerBtn:hover span:after {
  opacity: 1;
  right: 0;
}

/* row */
.rowClass {
  display: flex;
}

@media screen and (max-width: 992px) {
  .rowClass {
    display: block;
  }
}

/* introduction section */
.bgLeft {
  background-color: rgba(236, 236, 236, 0.8);
}
.bgRight {
  background-color: rgba(207, 216, 220, 0.8);
}
.videoClass {
  padding: 30px;
}

@media screen and (max-width: 500px) {
  .videoClass {
    padding-left: 0px;
    padding-right: 0px;
  }
}

/* instructor section */
#instructorSection {
  text-align: center;
  background-image: url(../assets/instructor-intro.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  color: #bacee6;
  padding: 4em 0 6em 0;
  height: auto;
  padding: 0px;
}

#instructorSection .inner {
  background-color: rgba(66, 161, 122, 0.85);
  padding: 40px 0px 50px;
}

.instructor-h1 {
  color: white;
  font-size: 40px;
}

.instructor-p {
  padding: 10px 50px;
  margin: 10px 80px 30px 80px;
  font-size: 20px;
  color: #e0e0e0;
}

.instructor-uni {
  color: white;
  font-size: 1.5em;
}
.instructor-name {
  color: #e0e0e0;
  font-size: 1.1em;
}

.instructorBtn {
  font-size: 1.3em;
  padding: 1em 1em;
  color: white;
  background: none;
  transition: 0.5s;
}

.instructorBtn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.instructorBtn span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -10px;
  transition: 0.5s;
  color: white;
}

.instructorBtn:hover,
.instructorBtn:active,
.instructorBtn:focus {
  color: white;
  /* background: #6d4c41; */
  opacity: 0.65;
  box-shadow: 0 0 0 1px rgba(144, 144, 144, 0.25);
}

.instructorBtn:hover span {
  padding-right: 20px;
}

.instructorBtn:hover span:after {
  opacity: 1;
  right: 0;
}

@media screen and (max-width: 736px) {
  .instructor-p {
    margin: 10px auto 30px auto;
    padding: 0 12px;
  }
}

@media screen and (max-width: 420px) {
  .instructorBtn {
    font-size: 0.95em;
    padding: 1em 1em;
    color: white;
    background: none;
    transition: 0.5s;
  }
}

/* course */
#courseSection {
  text-align: center;
  background-image: url(../assets/course-intro1.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  color: #bacee6;
  padding: 4em 0 6em 0;
  height: auto;
  padding: 0px;
}

#courseSection .inner {
  background-color: rgba(224, 224, 224, 0.75);
  padding: 40px 0px 50px;
}

.course-el-row {
  padding-left: 60px;
  padding-right: 60px;
}

.course-h1 {
  color: #424242;
  font-size: 40px;
}

.course-p {
  padding: 10px 50px;
  margin: 10px 80px 30px 80px;
  font-size: 20px;
  color: #616161;
}

.course-card {
  background-color: rgba(255, 255, 255, 1);
  height: 150px;
  border: 0px;
}

.course-title {
  color: #424242;
}

.course-title:hover {
  color: rgba(61, 161, 106, 0.9);
}

.course-desc {
  font-size: 16px;
  color: #616161;
}

.courseBtn {
  font-size: 1.3em;
  padding: 1em 1em;
  color: #424242;
  background: none;
  transition: 0.5s;
  border: 1px solid rgba(66, 66, 66, 1);
}

.courseBtn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.courseBtn span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -10px;
  transition: 0.5s;
  color: rgba(66, 66, 66, 1);
}

.courseBtn:hover,
.courseBtn:active,
.courseBtn:focus {
  color: rgba(66, 66, 66, 1);
  /* background: rgba(61, 161, 106, 0.9); */
  opacity: 0.5;
  box-shadow: 0 0 0 1px rgba(144, 144, 144, 0.25);
}

.courseBtn:hover span {
  padding-right: 20px;
}

.courseBtn:hover span:after {
  opacity: 1;
  right: 0;
}



/* stories */
/* .storiesSection .inner {
  position: relative;
} */
.storiesSection {
  text-align: center;
  background-image: url(../assets/stories.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  color: #bacee6;
  padding: 3em 0 6em 0;
  height: auto;
  padding: 0px;
}

.storiesSection .inner {
  background-color: rgba(66, 66, 66, 0.7);
  padding: 0px 0px 50px 0px;
}

.quote-icon {
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-right: 5px;
  background-size: cover;
  background-color: white;
}

.stories-quote-open {
  mask: url("../assets/svg/format-quote-open.svg") no-repeat center;
}

.stories-quote-close {
  mask: url("../assets/svg/format-quote-close.svg") no-repeat center;
}

.stories-p {
  text-align: center;
  padding: 50px 120px 20px 120px;
  padding-bottom: auto;
  font-size: 26px;
  color: #fafafa;
}

.stories-h5 {
  text-align: right;
  padding-right: 120px;
  /* padding-bottom: 20px; */
  font-size: 20px;
  color: #fafafa;
}

::v-deep button.el-carousel__arrow {
  outline: 0px;
}



.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.storiesBtn {
  font-size: 1.3em;
  padding: 1em 1em;
  color: white;
  background: none;
  transition: 0.5s;
}

.storiesBtn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.storiesBtn span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -10px;
  transition: 0.5s;
  color: white;
}

.storiesBtn:hover,
.storiesBtn:active,
.storiesBtn:focus {
  color: white;
  /* background: rgba(0, 137, 123, 0.7); */
  opacity: 0.85;
  box-shadow: 0 0 0 1px rgba(144, 144, 144, 0.25);
}

.storiesBtn:hover span {
  padding-right: 20px;
}

.storiesBtn:hover span:after {
  opacity: 1;
  right: 0;
}

::v-deep .el-carousel__button {
  height: 14px;
  width: 14px;
  border-radius: 14px;
  outline: none;
}

/* .el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
} */

/* enroll */
#enrollSection {
  text-align: center;
  background-image: url(../assets/enrollment.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  color: #bacee6;
  padding: 4em 0 6em 0;
  height: auto;
  padding: 0px;
}

#enrollSection .inner {
  background-color: rgba(66, 161, 106, 0.85);
  padding: 80px 0px 80px;
}

#enrollSection h2 {
  font-size: 4em;
  line-height: 1em;
  margin: 0 0 1em 0;
  padding: 0;
  color: #fff;
}



.enrollBtn {
  font-size: 1.3em;
  padding: 1em 1em;
  color: white;
  background: none;
  transition: 0.5s;
}

.enrollBtn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.enrollBtn span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -10px;
  transition: 0.5s;
  color: white;
}

.enrollBtn:hover,
.enrollBtn:active,
.enrollBtn:focus {
  color: white;
  background: unset;
  opacity: 0.85;
  box-shadow: 0 0 0 1px rgba(144, 144, 144, 0.25);
}

.enrollBtn:hover span {
  padding-right: 20px;
}

.enrollBtn:hover span:after {
  opacity: 1;
  right: 0;
}

@media screen and (max-width: 992px) {
  .course-col {
    padding-bottom: 20px;
  }
  .course-card {
    background-color: rgba(255, 255, 255, 1);
    height: auto;
  }
}


@media screen and (max-width: 768px) {
  .bgLeft, .contact-p {
    padding: 12px !important;
  }
   .storiesBtn, .enrollBtn {
    font-size:0.95em
  }
  .course-el-row {
    padding-left: 1em;
    padding-right: 1em;
  }
  .course-card {
    background-color: rgba(255, 255, 255, 1);
    height: auto;
    padding: 0px;
  }
  .instructor-h1 {
    padding: 0 12px;
  }

  .course-p {
    padding: 0 12px;
    margin: 10px auto 30px auto;
  }
}

@media screen and (max-width: 660px) {
  .stories-p {
    text-align: center;
    padding: 50px 30px 20px 30px;
    padding-bottom: auto;
    font-size: 26px;
    color: #fafafa;
  }
  .stories-h5 {
    text-align: right;
    padding-right: 20px;
    /* padding-bottom: 20px; */
    font-size: 20px;
    color: #fafafa;
  }
}


@media screen and (max-width: 500px) {
  #enrollSection h2 {
    font-size: 3em;
    line-height: 1em;
    margin: 0 0 1em 0;
    padding: 0;
    color: #fff;
  }
}
@media screen and (max-width: 450px) {
  .stories-p {
    text-align: center;
    padding: 50px 10px 20px 10px;
    padding-bottom: auto;
    font-size: 18px;
    color: #fafafa;
  }
  .stories-h5 {
    text-align: right;
    padding-right: 10px;
    font-size: 16px;
    color: #fafafa;
  }
}

@media screen and (max-width: 420px) {
  .courseBtn {
    font-size: 0.95em;
    padding: 1em 1em;
    /* color: white; */
    background: none;
    transition: 0.5s;
  }
}
</style>
